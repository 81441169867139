<template>
  <el-dialog
      title="添加收款方式"
      width="30%"
      :visible.sync="states"
      :modal-append-to-body="false"
      @close="close">
    <el-form label-width="100px" :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form-item label="名称" prop="paymentName">
            <el-input v-model="form.paymentName" placeholder="请填写收款方式名称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form-item label="参数等级" prop="orders">
            <el-select v-model="form.orders" placeholder="请选择参数等级" style="width: 100%" @change="change">
              <el-option
                  v-for="item in ordersArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="parentSelect">
        <el-col :span="20" :offset="1">
          <el-form-item label="上级菜单" prop="parentId">
            <el-select v-model="form.parentId" placeholder="请选择上级菜单" style="width: 100%">
              <el-option
                  v-for="item in parentIdArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">提 交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "proceeds-payment-add",
  created() {
    this.queryParentIdArray()
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    payment: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      states: this.state,
      form: {
        paymentName: this.payment.paymentName,
        orders: this.payment.orders,
        parentId: this.payment.parentId === 0 ? '' : this.payment.parentId,
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      rules: {
        paymentName: [{required: true, message: '请填写预约项目名称', trigger: 'blur'}],
        orders: [{required: true, message: '请选择参数等级', trigger: 'change'}],
        parentId: [{required: true, message: '请选择上级菜单', trigger: 'change'}],
      },
      ordersArray: [{name: "一级菜单", value: 1},{name: "二级菜单", value: 2}],
      parentSelect: this.payment.orders === 2,
      parentIdArray: [],
    }
  },
  methods: {
    queryParentIdArray() {
      this.$axios({
        method: "GET",
        url: "/payment/parentPayment",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        this.parentIdArray = response.data.data
      })
    },
    close() {
      this.$emit("close");
    },
    change(val) {
      this.parentSelect = val === 2
    },
    submit() {
      this.$refs['form'].validate(valid=> {
        if (valid) {
          if (this.form.orders === 1) {
            this.form.parentId = 0
          }
          this.$axios({
            method: "PUT",
            url: '/payment/updatePayment',
            params: {
              id: this.payment.id,
              ...this.form
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? "修改成功" :response.data.msg,
              type: flag? 'success' :'error',
              duration: 1000,
            })
            if (flag) {
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>